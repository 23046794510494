import React from 'react'

// Components
import Layout from '../components/Layout'
import {GalleryLandscapes}  from '../components/Gallery'


// Views
import Hero from '../views/Hero'

const LandscapesPage = ({}) => (
  <>
    <Layout />
    <GalleryLandscapes />
  </>
)

export default LandscapesPage
